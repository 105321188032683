"use strict";

//const URL_BASE = 'https://servicos.jsoftware.com.br/api-em/' // homologacao
//const URL_BASE = 'http://localhost:5477/'
const URL_BASE = 'https://api.escalamedica.com.br/'

//const accessToken = localStorage.getItem('authorization')
const VERSAO = "1.1.0.14"

export const BASE_CONSTANTS = {
    urlBase: URL_BASE,
    versao: VERSAO
}