<template>
  <div>
    <select-cor
      ref="selectCor"
      id="selectCor"
      @executou="
        (r) => {
          if (r.status === 'success') atualizarCor(r.color);
        }
      "
    />

    <edit-situacao-local
      ref="editSituacaoLocal"
      @executou="
        (r) => {
          if (r.status === 'success') this.atualizarRegistroSituacao(r.entity);
        }
      "
    />

    <edit-situacao-fechamento-local
      ref="editSituacaoFechamentoLocal"
      @executou="
        (r) => {
          if (r.status === 'success')
            this.atualizarRegistroSituacaoFechamento(r.entity);
        }
      "
    />

    <edit-tipo-local
      ref="editTipoLocal"
      @executou="
        (r) => {
          if (r.status === 'success') this.atualizarRegistroTipo(r.entity);
        }
      "
    />

    <select-profissional
      ref="selectProfissional"
      @executou="
        (r) => {
          if (r.status === 'success') adicionarProfissional(r.ids);
        }
      "
    />

    <confirma
      ref="conf"
      @respondeu="
        (r) => {
          if (r === 'S') retornoConfirma();
        }
      "
    >
      <template v-slot:texto>{{ msgConfirma }}</template>
    </confirma>

    <card v-if="entity">
      <h4 slot="header" class="card-title">
        {{ this.idLocal > 0 ? ('Atualizar Local ' + this.entity.descricao) : 'Novo
              Local' }}
      </h4>
      <form>
        <div class="mx-1">
          <b-tabs content-class="mt-3" style="min-height: 500px">
            <b-tab title="Local" active>
              <div class="row">
                <div class="col-md-1">
                  <b-form-group label="Código" label-for="input-codigo">
                    <b-form-input
                      id="input-codigo"
                      type="text"
                      label="Código"
                      placeholder="Código"
                      maxlength="50"
                      v-model="entity.codigo"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-11">
                  <b-form-group label="Descrição" label-for="input-descricao">
                    <b-form-input
                      id="input-descricao"
                      type="text"
                      label="Descrição"
                      placeholder="Descrição"
                      maxlength="200"
                      v-model="entity.descricao"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2">
                  <b-form-group
                    label="Período de apuração"
                    label-for="input-periodo-apuracao"
                  >
                    <b-input-group id="input-periodo-apuracao">
                      <b-form-input
                        type="number"
                        placeholder="Inicial"
                        max="31"
                        maxlength="2"
                        v-model="entity.periIniApu"
                      />
                      <b-input-group-text>até</b-input-group-text>
                      <b-form-input
                        type="number"
                        max="31"
                        placeholder="Final"
                        maxlength="2"
                        v-model="entity.periFimApu"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group label="Cor" label-for="input-cor">
                    <div
                      class="box-color"
                      :style="{ background: entity.cor }"
                      @click="abrirCor"
                    >
                      <div style="padding: 2px; font-size: 12px; color: blue">
                        {{
                          entity.cor === null || entity.cor === ""
                            ? "clique aqui para selecionar a cor"
                            : ""
                        }}
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <b-form-group label="Endereço" label-for="input-endereco">
                    <b-form-input
                      readonly
                      id="input-endereco"
                      type="text"
                      placeholder="Endereço"
                      v-model="entity.endereco"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group label="Cidade" label-for="input-cidade">
                    <b-form-input
                      readonly
                      id="input-cidade"
                      type="text"
                      placeholder="Cidade"
                      v-model="entity.cidade"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <b-form-group label="UF" label-for="input-uf">
                    <b-form-input
                      readonly
                      id="input-uf"
                      type="text"
                      placeholder="UF"
                      v-model="entity.uf"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <b-form-group label="Latitude" label-for="input-latitude">
                    <b-form-input
                      readonly
                      id="input-latitude"
                      type="text"
                      placeholder="Latitude"
                      v-model="entity.lat"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <b-form-group label="Longitude" label-for="input-longitude">
                    <b-form-input
                      readonly
                      id="input-longitude"
                      type="text"
                      placeholder="Longitude"
                      v-model="entity.lng"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group
                    label="Raio Check-in"
                    label-for="input-raio-checkin"
                  >
                    <b-input-group id="input-raio-checkin">
                      <b-form-input
                        type="number"
                        max="100000"
                        placeholder="Raio Checkin"
                        maxlength="6"
                        v-model="entity.raioCheckin"
                      />
                      <b-input-group-text>metros</b-input-group-text>
                    </b-input-group>
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-row class="m-0">
                    <div class="col-6 p-0">
                      <b-form-group
                        label="Limite Check-in antes"
                        label-for="input-limite-checkin-antes"
                      >
                        <b-form-input
                          id="input-limite-checkin-antes"
                          type="time"
                          label="antes"
                          placeholder="hh:mm"
                          v-model="entity.checkinAntes"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6 py-0">
                      <b-form-group
                        label="depois"
                        label-for="input-limite-checkin-depois"
                      >
                        <b-form-input
                          id="input-limite-checkin-depois"
                          type="time"
                          label="depois"
                          placeholder="hh:mm"
                          v-model="entity.checkinDepois"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-row>
                </div>

                <div class="col-md-2">
                  <b-row class="m-0">
                    <div class="col-6 p-0">
                      <b-form-group
                        label="Limite Check-out antes"
                        label-for="input-limite-checkout-antes"
                      >
                        <b-form-input
                          id="input-limite-checkout-antes"
                          type="time"
                          label="antes"
                          placeholder="hh:mm"
                          v-model="entity.checkoutAntes"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6 py-0">
                      <b-form-group
                        label="depois"
                        label-for="input-limite-checkout-depois"
                      >
                        <b-form-input
                          id="input-limite-checkout-depois"
                          type="time"
                          label="depois"
                          placeholder="hh:mm"
                          v-model="entity.checkoutDepois"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-row>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <b-form-group label="Ativo" label-for="input-ativo">
                    <b-form-checkbox
                      v-model="entity.ativo"
                      value="S"
                      unchecked-value="N"
                      switch
                    ></b-form-checkbox>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn-info btn-fill float-right"
                      @click.prevent="salvar"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title="Situações" lazy @click="buscarSituacoes">
              <b-table
                :items="situacoes"
                :fields="fieldsSit"
                sticky-header="true"
              >
                <template v-slot:cell(previsto)="row">
                  {{ row.item.previsto }}
                </template>

                <template v-slot:cell(executado)="row">
                  {{ row.item.executado }}
                </template>
                <template v-slot:cell(exportar)="row">
                  {{ row.item.exportar }}
                </template>
                <template v-slot:cell(divulga)="row">
                  {{ row.item.divulga }}
                </template>
                <template v-slot:cell(avista)="row">
                  {{ row.item.avista }}
                </template>
                <template v-slot:cell(faturar)="row">
                  {{ row.item.faturar }}
                </template>
                <template v-slot:cell(aberto)="row">
                  {{ row.item.aberto }}
                </template>
                <template v-slot:cell(checkin)="row">
                  {{ row.item.checkin }}
                </template>
                <template v-slot:cell(checkout)="row">
                  {{ row.item.checkout }}
                </template>
                <template v-slot:cell(pedidoDesistencia)="row">
                  {{ row.item.pedidoDesistencia }}
                </template>
                <template #cell()="data">
                  {{ data.value }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="editarSituacao(row.item.id)"
                    class="mr-1"
                    variant="success"
                    title="Editar"
                  >
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button
                    size="sm"
                    @click="confirmaExcluirSituacao(row.item.id)"
                    class="mr-1"
                    variant="danger"
                    title="Excluir"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>

              <b-row>
                <div class="col-md-12">
                  <div class="w-100">
                    <b-button
                      variant="info"
                      size="sm"
                      class="btn-fill"
                      @click="novaSituacao"
                    >
                      Adicionar
                    </b-button>
                  </div>
                </div>
              </b-row>
            </b-tab>

            <b-tab
              title="Sit. Fechamento"
              lazy
              @click="buscarSituacoesFechamento"
            >
              <b-table
                v-if="situacoesFechamento"
                :items="situacoesFechamento"
                :fields="fieldsSitFat"
                :fixed="true"
                sticky-header="400px"
              >
                <template v-slot:cell(padrao)="row">
                  {{ row.item.padrao }}
                </template>
                <template v-slot:cell(faturar)="row">
                  {{ row.item.faturar }}
                </template>
                <template v-slot:cell(atualizarPreco)="row">
                  {{ row.item.atualizarPreco }}
                </template>

                <template #cell()="data">
                  {{ data.value }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="editarSituacaoFechamento(row.item.id)"
                    class="mr-1"
                    variant="success"
                    title="Editar"
                  >
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button
                    size="sm"
                    @click="confirmaExcluirSituacaoFechamento(row.item.id)"
                    class="mr-1"
                    variant="danger"
                    title="Excluir"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>

              <b-row>
                <div class="col-md-12">
                  <div class="w-100">
                    <b-button
                      variant="info"
                      size="sm"
                      class="btn-fill"
                      @click="novaSituacaoFechamento"
                    >
                      Adicionar
                    </b-button>
                  </div>
                </div>
              </b-row>
            </b-tab>

            <b-tab title="Profissionais" lazy @click="buscarProfissionais">
              <b-table
                v-if="profissionais"
                :items="profissionais"
                :fields="fieldsProfissional"
                :fixed="true"
                sticky-header="350px"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(seleciona)="row">
                  <b-form-checkbox
                    v-model="row.item.seleciona"
                    value="S"
                    unchecked-value="N"
                  ></b-form-checkbox>
                </template>

                <template #cell()="data">
                  {{ data.value }}
                </template>

                <template v-slot:cell(cpf)="row">
                  {{ formatarCpf(row.item.cpf) }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="confirmaExcluirProfissional(row.item.id)"
                    class="mr-1"
                    variant="danger"
                    title="Excluir"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>

              <b-row>
                <div class="col-md-12">
                  <div class="w-100">
                    <b-button
                      variant="info"
                      size="sm"
                      class="btn-fill"
                      @click="abrirSelecionaProfissional"
                    >
                      Adicionar
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1 btn-fill"
                      @click="confirmaExcluirProfissional(null)"
                      v-if="existeProfissionalSelecionado"
                    >
                      <b-icon icon="trash" aria-hidden="true"></b-icon>
                      Excluir Selecionados
                    </b-button>
                  </div>
                </div>
              </b-row>
            </b-tab>

            <b-tab title="Tipos de Serviço" lazy @click="buscarTiposServico">
              <b-table
                :items="tipos"
                :fields="fieldsServico"
                sticky-header="true"
              >
                <template v-slot:cell(valorVenda)="data">
                  {{
                    data.value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                      maximumFractionDigits: 2,
                    })
                  }}
                </template>

                <template v-slot:cell(valorCompra)="data">
                  {{
                    data.value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                      maximumFractionDigits: 2,
                    })
                  }}
                </template>

                <template v-slot:cell(dtValidadeIni)="data">
                  {{ strDateFormat(data.value, 'dd/MM/yyyy') }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="editarTipo(row.item.id)"
                    class="mr-1"
                    variant="success"
                    title="Editar"
                  >
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button
                    size="sm"
                    @click="confirmaExcluirTipo(row.item.id)"
                    class="mr-1"
                    variant="danger"
                    title="Excluir"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>

              <b-row>
                <div class="col-md-12">
                  <div class="w-100">
                    <b-button
                      variant="info"
                      size="sm"
                      class="btn-fill"
                      @click="novoTipo"
                    >
                      Adicionar
                    </b-button>
                  </div>
                </div>
              </b-row>
            </b-tab>
          </b-tabs>

          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-danger btn-fill float-right mr-1"
                  @click.prevent="voltar"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import AgendaService from "../../../service/AgendaService";
import Card from "src/components/Cards/Card.vue";
import appUtils from "@/composables/utils";
import SelectCor from "../../comuns/SelectCor.vue";
import EditSituacaoLocal from "./EditSituacaoLocal.vue";
import EditTipoLocal from "./EditTipoLocal.vue";
import EditSituacaoFechamentoLocal from "./EditSituacaoFechamentoLocal.vue";
import Confirma from "../../comuns/Confirma";
import SelectProfissional from "../../comuns/SelectProfissional.vue";

export default {
  props: ["idLocal", "idPai"],
  components: {
    Card,
    SelectCor,
    EditSituacaoLocal,
    EditTipoLocal,
    EditSituacaoFechamentoLocal,
    Confirma,
    SelectProfissional,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      entity: null,
      situacoes: null,
      situacoesFechamento: null,
      profissionais: null,
      tipos: null,
      idSituacaoLocal: null,
      idSituacaoFechamento: null,
      idTipoLocal: null,
      confAtual: null,
      msgConfirma: null,
      fieldsSit: [
        {
          key: "dscSituacao",
          label: "Situação",
          sortable: true,
          sortDirection: "asc",
          thStyle: { width: "20%" },
        },
        {
          key: "previsto",
          label: "Previsto",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "executado",
          label: "Executado",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "exportar",
          label: "Exportar",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "divulga",
          label: "Divulga",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "faturar",
          label: "Faturar",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "aberto",
          label: "Aberto",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "checkin",
          label: "Checkin",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "checkout",
          label: "Checkout",
          type: "check",
          thStyle: { width: "5%" },
        },
        {
          key: "pedidoDesistencia",
          label: "Desiste",
          type: "check",
          thStyle: { width: "5%" },
        },
        { key: "actions", label: "Ação", thStyle: { width: "20%" } },
      ],
      fieldsSitFat: [
        {
          key: "dscSituacao",
          label: "Situação",
          sortable: true,
          sortDirection: "asc",
          thStyle: { width: "68%" },
        },
        {
          key: "padrao",
          label: "Padrão",
          type: "check",
          thStyle: { width: "8%" },
        },
        {
          key: "faturar",
          label: "Faturar",
          type: "check",
          thStyle: { width: "8%" },
        },
        {
          key: "atualizarPreco",
          label: "Preço",
          type: "check",
          thStyle: { width: "8%" },
        },
        { key: "actions", label: "Ação", thStyle: { width: "20%" } },
      ],
      fieldsProfissional: [
        { key: "seleciona", type: "check", thStyle: { width: "8%" } },
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          thStyle: { width: "40%" },
        },
        { key: "crm", label: "Crm", sortable: true, thStyle: { width: "20%" } },
        { key: "cpf", label: "cpf", sortable: true, thStyle: { width: "20%" } },
        { key: "actions", label: "Ação", thStyle: { width: "20%" } },
      ],
      fieldsServico: [
        {
          key: "dscTipo",
          label: "Tipo de serviço",
          sortable: true,
          sortDirection: "asc",
          thStyle: { width: "31%" },
        },
        { key: "duracaoStr", label: "Duração", thStyle: { width: "7%" } },
        { key: "limiteDiaReal", label: "Limite Dia", thStyle: { width: "5%" } },
        { key: "limiteMesReal", label: "Limite Mês", thStyle: { width: "5%" } },
        { key: "valorVenda", label: "Valor venda", thStyle: { width: "9%" } },
        { key: "valorCompra", label: "Valor compra", thStyle: { width: "9%" } },
        { key: "dtValidadeIni", label: "Início validade", thStyle: { width: "7%" } },
        { key: "dtValidadeFim", label: "Fim validade", thStyle: { width: "7%" } },
        { key: "actions", label: "Ação", thStyle: { width: "20%" } },
      ],
    };
  },
  mounted() {
    let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

    if (this.idLocal > 0) {
      this.carregaDados();
    } else {
      this.entity = {
        codigo: "",
        descricao: "",
        periIniApu: 1,
        periFimApu: 31,
        idPai: this.idPai !== null ? this.idPai : null,
        idEmpresa: dadosUsuario.grupo.empresa.id,
        cor: null,
        ativo: "S",
      };
    }
  },
  methods: {
    carregaDados() {
      const { toast, ativarShow, desativarShow } = appUtils(this);

      ativarShow("carregarDados");
      AgendaService.editLocal(this.idLocal)
        .then((resposta) => {
          this.entity = resposta.data.obj;
          desativarShow("carregarDados");
        })
        .catch((err) => {
          desativarShow("carregarDados");
        });
    },
    voltar() {
      this.$emit("fecharedit");
    },
    salvar() {
      const { toast, ativarShow, desativarShow } = appUtils(this);
      ativarShow("salvarLocal");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      (this.entity.idUsuario = dadosUsuario.idPessoa),
        AgendaService.saveLocal(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              let ret = {
                status: "success",
                entity: retorno.obj,
              };
              this.$emit("executou", ret);
              toast(retorno.mensagens[0].descricao, "Sucesso", "success");
              var v = this;
              setTimeout(function () {
                v.voltar();
              }, 2000);
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, "Falha", "danger");
              }
            }
            desativarShow("salvarLocal");
          })
          .catch((error) => {
            desativarShow("salvarLocal");
            toast(error, "Falha", "danger");
          });
    },
    abrirCor() {
      this.$refs.selectCor.show(this.entity.cor);
    },
    atualizarCor(cor) {
      this.entity.cor = cor;
    },
    novaSituacao() {
      this.$refs.editSituacaoLocal.novo(this.entity.id);
    },
    editarSituacao(id) {
      this.$refs.editSituacaoLocal.abrir(id);
    },
    confirmaExcluirSituacao(id) {
      this.msgConfirma = "Confirma exclusão da Situação do local?";
      this.confAtual = "situacao";
      this.idSituacaoLocal = id;
      this.$refs.conf.show();
    },
    novoTipo() {
      this.$refs.editTipoLocal.novo(this.entity.id);
    },
    editarTipo(id) {
      this.$refs.editTipoLocal.abrir(id);
    },
    confirmaExcluirTipo(id) {
      this.msgConfirma = "Confirma exclusão do Tipo do local?";
      this.confAtual = "tipo";
      this.idTipoLocal = id;
      this.$refs.conf.show();
    },
    retornoConfirma() {
      if (this.confAtual === "situacao") this.excluirSituacaoLocal();
      else if (this.confAtual === "situacaoFechamento")
        this.excluirSituacaoFechamento();
      else if (this.confAtual === "tipo") this.excluirTipoLocal();
      else this.excluirProfissionalLocal();
    },
    excluirSituacaoLocal() {
      const { toast, ativarShow, desativarShow } = appUtils(this);
      ativarShow("deleteSituacaoLocal");

      AgendaService.deleteSituacaoLocal(this.idSituacaoLocal)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.situacoes = this.situacoes.filter((i) => {
              return i.id !== this.idSituacaoLocal;
            });
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, "Falha", "danger");
            }
          }
          desativarShow("deleteSituacaoLocal");
        })
        .catch((error) => {
          desativarShow("deleteSituacaoLocal");
          toast(error, "Ações do usuário", "danger");
        });
    },
    atualizarRegistroSituacao(entity) {
      let index = this.situacoes.indexOf(
        this.situacoes.find((i) => i.id === entity.id)
      );
      if (index >= 0) {
        this.situacoes.splice(index, 1, entity);
      } else {
        this.situacoes.push(entity);
      }
    },
    novaSituacaoFechamento() {
      this.$refs.editSituacaoFechamentoLocal.novo(this.entity.id);
    },
    editarSituacaoFechamento(id) {
      this.$refs.editSituacaoFechamentoLocal.abrir(id);
    },
    confirmaExcluirSituacaoFechamento(id) {
      this.msgConfirma =
        "Confirma exclusão da Situação de fechamento do local?";
      this.confAtual = "situacaoFechamento";
      this.idSituacaoFechamento = id;
      this.$refs.conf.show();
    },
    excluirSituacaoFechamento() {
      const { toast, ativarShow, desativarShow } = appUtils(this);
      ativarShow("excluirSituacaoFechamento");

      AgendaService.deleteSituacaoFechamentoLocal(this.idSituacaoFechamento)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.situacoesFechamento = this.situacoesFechamento.filter((i) => {
              return i.id !== this.idSituacaoFechamento;
            });
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, "Falha", "danger");
            }
          }
          desativarShow("excluirSituacaoFechamento");
        })
        .catch((error) => {
          desativarShow("excluirSituacaoFechamento");
          toast(error, "Ações do usuário", "danger");
        });
    },
    atualizarRegistroSituacaoFechamento(entity) {
      let index = this.situacoesFechamento.indexOf(
        this.situacoesFechamento.find((i) => i.id === entity.id)
      );
      if (index >= 0) {
        this.situacoesFechamento.splice(index, 1, entity);
      } else {
        this.situacoesFechamento.push(entity);
      }
    },
    excluirTipoLocal() {
      const { toast, ativarShow, desativarShow } = appUtils(this);
      ativarShow("deleteTipoLocal");

      AgendaService.deleteTipoLocal(this.idTipoLocal)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.tipos = this.tipos.filter((i) => {
              return i.id !== this.idTipoLocal;
            });
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, "Falha", "danger");
            }
          }
          desativarShow("deleteTipoLocal");
        })
        .catch((error) => {
          desativarShow("deleteTipoLocal");
          toast(error, "Ações do usuário", "danger");
        });
    },
    atualizarRegistroTipo(entity) {
      let index = this.tipos.indexOf(
        this.tipos.find((i) => i.id === entity.id)
      );
      if (index >= 0) {
        this.tipos.splice(index, 1, entity);
      } else {
        this.tipos.push(entity);
      }
    },

    buscarProfissionais() {
      if (this.profissionais === null) {
        const { toast } = appUtils(this);

        this.show = true;

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = (dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idLocal: this.entity.id,
        });

        AgendaService.buscarProfissionaisLocal(dadosConsulta)
          .then((resposta) => {
            this.profissionais = resposta.data.obj.lista;
            this.show = false;
          })
          .catch((error) => {
            this.show = false;
          });
      }
    },
    buscarSituacoes() {
      if (this.situacoes === null) {
        const { toast } = appUtils(this);

        this.show = true;

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = (dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idLocal: this.entity.id,
        });

        AgendaService.listaSituacoesLocal(dadosConsulta)
          .then((resposta) => {
            this.situacoes = resposta.data.obj;
            this.show = false;
          })
          .catch((error) => {
            this.show = false;
          });
      }
    },
    buscarSituacoesFechamento() {
      if (this.situacoesFechamento === null) {
        const { toast } = appUtils(this);

        this.show = true;

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = (dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idLocal: this.entity.id,
        });

        AgendaService.listaSituacoesFechamentoLocal(dadosConsulta)
          .then((resposta) => {
            this.situacoesFechamento = resposta.data.obj;
            this.show = false;
          })
          .catch((error) => {
            this.show = false;
          });
      }
    },
    buscarTiposServico() {
      if (this.tipos === null) {
        const { toast } = appUtils(this);

        this.show = true;

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = (dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idLocal: this.entity.id,
        });

        AgendaService.listaTiposLocal(dadosConsulta)
          .then((resposta) => {
            this.tipos = resposta.data.obj;
            this.show = false;
          })
          .catch((error) => {
            this.show = false;
          });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.deleted === "S") return "table-danger";
    },
    confirmaExcluirProfissional(id) {
      this.msgConfirma =
        "Confirma exclusão " + (id ? "do profissional?" : "dos profissionais?");
      this.confAtual = "profissional";
      if (id) {
        this.idsProfissional = [];
        this.idsProfissional.push(id);
      } else {
        this.idsProfissional = this.profissionais
          .filter(function (elem) {
            return elem.seleciona === "S";
          })
          .map((elem) => {
            return elem.id;
          });
      }

      this.$refs.conf.show();
    },
    excluirProfissionalLocal() {
      const { toast, ativarShow, desativarShow } = appUtils(this);
      ativarShow("excluirProfissionalLocal");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dados = {
        idUsuario: dadosUsuario.idPessoa,
        ids: this.idsProfissional,
      };

      AgendaService.deleteProfissionalLocal(dados)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.profissionais = this.profissionais.filter((i) => {
              return !this.idsProfissional.includes(i.id);
            });
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, "Falha", "danger");
            }
          }
          desativarShow("excluirProfissionalLocal");
        })
        .catch((error) => {
          desativarShow("excluirProfissionalLocal");
          toast(error, "Ações do usuário", "danger");
        });
    },
    abrirSelecionaProfissional() {
      let idsEliminar = this.profissionais.map((elem) => elem.id);
      this.$refs.selectProfissional.abrir(idsEliminar);
    },
    adicionarProfissional(regs) {
      const { toast, ativarShow, desativarShow } = appUtils(this);

      ativarShow("adicionarProfissional");

      let idsProfissional = regs.map((elem) => elem.id);

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dados = {
        idUsuario: dadosUsuario.idPessoa,
        idLocal: this.entity.id,
        idsProfissional: idsProfissional,
      };

      AgendaService.saveProfissionalLocal(dados)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.profissionais = null;
            this.buscarProfissionais();
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, "Falha", "danger");
            }
          }
          desativarShow("adicionarProfissional");
        })
        .catch((error) => {
          desativarShow("adicionarProfissional");
          toast(error, "Ações do usuário", "danger");
        });
    },
    formatarCpf(text) {
      const { formatarCpf } = appUtils(this);
      return formatarCpf(text);
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
  },
  computed: {
    existeProfissionalSelecionado() {
      return (
        this.profissionais &&
        this.profissionais.filter(function (elem) {
          return elem.seleciona === "S";
        }).length > 0
      );
    },
  },
};

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>

<style>
.box-color {
  border-width: 0;
  border-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  cursor: pointer;
}
</style>